import React, { lazy, Suspense } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import FormContatti from "../components/form-contatti";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const Contatti = () => {
  return (
    <Layout>
      <Seo title={`Contatti`} description={`Contatti`} />

      <div className="box-intro box-intro--page-name">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/Xf2icR8i12xleigw/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-intro__page-name">
                  <span>Richiedi</span> contatto
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">Contattaci!</h2>
                <div className="box-article__text">
                  <p>
                    Se sei interessato a collaborare o per qualsiasi necessità, scrivici. Il nostro
                    staff ti risponderà al più presto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FormContatti />
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Contatti;
