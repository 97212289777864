import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "./link";

const FormContatti = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      privacy: true,
    },
    mode: "onChange",
  });

  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    setSubmitError(false);
    try {
      const response = await fetch("/api/form-contact", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(
          `Error calling /api/form-contact: ${response.status} ${response.statusText}`
        );
      }
      const result = await response.json();
      console.log("result: ", result);
      setSubmitted(true);
    } catch (err) {
      setSubmitError(true);
      console.error("Error form contatti: ", err);
    }
  };

  return (
    <div className="container-md">
      <div className="row">
        <div className="col-12 col-xl-10 offset-xl-1">
          {submitted ? (
            <div className="box-article">
              <div className="container-md">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="box-article__title">Grazie per averci contattato.</h2>
                    <div className="box-article__text">
                      <p>Verrai contattato al più presto da un nostro operatore.</p>
                    </div>
                    <div className="d-flex pb-5 justify-content-center">
                      <Link to="/" className="btn-link btn-link--small">
                        <span className="btn-link__circle"></span>
                        <span className="btn-link__text">
                          Torna alla <span className="d-block bold">homepage</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="name"
                        id="name"
                        placeholder=" "
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Specificare un nome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un nome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="name">
                        Nome *
                      </label>
                    </div>
                    {errors.name ? (
                      <span className="d-block form__error">{errors.name.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="surname"
                        id="surname"
                        placeholder=" "
                        {...register("surname", {
                          required: {
                            value: true,
                            message: "Specificare un cognome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un cognome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="surname">
                        Cognome *
                      </label>
                    </div>
                    {errors.surname ? (
                      <span className="d-block form__error">{errors.surname.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="company"
                        id="company"
                        placeholder=" "
                        {...register("company", {
                          required: {
                            value: true,
                            message: "Specificare l'azienda.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="company">
                        Azienda *
                      </label>
                    </div>
                    {errors.company ? (
                      <span className="d-block form__error">{errors.company.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="email"
                        className="form__input-text"
                        name="email"
                        id="email"
                        placeholder=" "
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Specificare un indirizzo email.",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Specificare un indirizzo email valido.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="email">
                        Email *
                      </label>
                    </div>
                    {errors.email ? (
                      <span className="d-block form__error">{errors.email.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="telefono"
                        id="telefono"
                        placeholder=" "
                        {...register("telefono", {
                          required: {
                            value: true,
                            message: "Specificare un numero di telefono.",
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Specificare un numero di telefono valido.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="telefono">
                        Telefono *
                      </label>
                    </div>
                    {errors.telefono ? (
                      <span className="d-block form__error">{errors.telefono.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="cap"
                        id="cap"
                        placeholder=" "
                        {...register("cap")}
                      />
                      <label className="form__label" htmlFor="cap">
                        CAP
                      </label>
                    </div>
                    {/* {errors.cap ? (
                      <span className="d-block form__error">{errors.cap.message}</span>
                    ) : null} */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__group">
                    <label className="checkbox">
                      <div>
                        Confermo di aver preso visione dell'informativa sul trattamento dei dati
                      </div>
                      <input
                        type="checkbox"
                        name="privacy"
                        id="privacy"
                        className="checkbox__input"
                        {...register("privacy", {
                          required: {
                            value: true,
                            message: "Confermare la presa visione dell'informativa.",
                          },
                        })}
                      />
                      <span className="checkbox__mark"></span>
                    </label>
                    {errors.privacy ? (
                      <span className="d-block form__error">{errors.privacy.message}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-link btn-link--submit"
                    >
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Invia richiesta <span className="d-block bold">contatto</span>
                      </span>
                    </button>
                  </div>
                  {submitError && (
                    <div className="text-center mt-4">Ops, si è verificato un errore. Riprova.</div>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormContatti;
